<script lang="ts" setup>
import { useAuth } from '@/store/auth';
const auth = useAuth();
const router = useRouter();
const localePath = useLocalePath();

if (auth.user) {
  router.push(localePath('profiles'));
}
</script>

<template>
  <section class="container mx-auto my-10 p-4 sm:p-6">
    <PageTitle>
      {{ $t('pages.school_auth_register.title') }}
    </PageTitle>

    <div class="mt-10 flex flex-col items-start md:flex-row md:justify-between">
      <h2 class="order-2 mt-6 text-xl text-neutral-600 md:order-1 md:mt-0">
        {{ $t('pages.school_auth_register.subsubtitle') }}
      </h2>

      <div class="z-20 order-1 flex flex-col items-start md:order-2 md:items-end">
        <AppButton :to="localePath('register')">
          {{ $t('pages.school_auth_register.signup') }}
        </AppButton>

        <AppButton :to="localePath('auth')" :outline="true" state="secondary" class="mt-4">
          {{ $t('pages.school_auth_register.login_btn') }}
        </AppButton>
      </div>
    </div>

    <TutoRegister class="mt-16" />
  </section>
</template>
