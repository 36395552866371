<template>
  <ol class="grid gap-6 font-semibold md:grid-cols-4">
    <li class="flex flex-col">
      <div class="flex">
        <span
          class="inline-flex h-8 w-8 items-center justify-center rounded bg-primary text-xl text-primary-contrast"
        >
          1
        </span>
        <p class="ml-3 flex-1">
          {{ $t('pages.school_auth_register.text_step1') }}
        </p>
      </div>

      <img src="@/assets/images/register/step1.svg" class="mt-3 w-full max-w-xs opacity-75" />
    </li>
    <li class="flex flex-col">
      <div class="flex">
        <span
          class="inline-flex h-8 w-8 items-center justify-center rounded bg-primary text-xl text-primary-contrast"
        >
          2
        </span>
        <p class="ml-3 flex-1">
          {{ $t('pages.school_auth_register.text_step3') }}
        </p>
      </div>

      <img src="@/assets/images/register/step2.svg" class="mt-3 w-full max-w-xs opacity-75" />
    </li>
    <li class="flex flex-col">
      <div class="flex">
        <span
          class="inline-flex h-8 w-8 items-center justify-center rounded bg-primary text-xl text-primary-contrast"
        >
          3
        </span>
        <p class="ml-3 flex-1">
          {{ $t('pages.school_auth_register.text_step4') }}
        </p>
      </div>

      <img src="@/assets/images/register/step3.svg" class="mt-3 w-full max-w-xs opacity-75" />
    </li>
    <li class="flex flex-col">
      <div class="flex">
        <span
          class="inline-flex h-8 w-8 items-center justify-center rounded bg-primary text-xl text-primary-contrast"
        >
          4
        </span>
        <p class="ml-3 flex-1">
          {{ $t('pages.school_auth_register.text_step5') }}
        </p>
      </div>

      <img src="@/assets/images/register/step4.svg" class="mt-3 w-full max-w-xs opacity-75" />
    </li>
  </ol>
</template>
